<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <main>
      <section id="account_apply">
        <div class="w1300 layout_container">

          <!-- 內頁主標題 -->
          <div class="main_title_box">
            <router-link
              to="/about/according"
              class="main_title_btn main_title_btn-evaluate small"
              target="_blank"
            >
              評核實施辦法
            </router-link>
            <router-link
              to="/account/search/profession"
              class="main_title_btn main_title_btn-apply small"
              target="_blank"
            >
              專科申請查詢
            </router-link>
            <h3 class="main_title txt-bold">專科醫師申請</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <!-- form area -->
          <div class="form_box">
            <form id="form_account_apply" action="" method="post" @submit.prevent>

              <!-- 基本資料 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">基本資料</h4>
                </div>
              </div>
              <div class="form_interactive">
                <div class="half">
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">姓名</p>
                    <p class="fixed_info_content">{{ fixedInfo.realname }}</p>
                  </div>
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">會員編號</p>
                    <p class="fixed_info_content">{{ fixedInfo.memberNo }}</p>
                  </div>
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">性別</p>
                    <p class="fixed_info_content">
                      {{ userGender(fixedInfo.gender, fixedInfo.id_number) }}
                    </p>
                  </div>
                </div>
                <div class="half">
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">出生年月日</p>
                    <p class="fixed_info_content">{{ fixedInfo.birthday }}</p>
                  </div>
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">身分證字號/居留證號碼</p>
                    <p class="fixed_info_content">{{ fixedInfo.id_number }}</p>
                  </div>
                </div>
                <TextInput
                  class="w50"
                  v-model.trim="user.phone"
                  :item="inputFormat.phoneInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^09\d{8}$/, '手機格式錯誤']
                  ]"
                  :maxlength="10"
                  :disabled="isDisabled"
                />
                <div class="half">
                  <TextInput
                    class="w50"
                    v-model.trim="user.memo.office_tel"
                    :item="inputFormat.telePublicInput"
                    :maxlength="11"
                    :disabled="isDisabled"
                  />
                  <TextInput
                    class="w50"
                    v-model.trim="user.memo.tel"
                    :item="inputFormat.telInput"
                    :maxlength="11"
                    :disabled="isDisabled"
                  />
                </div>
              </div>

              <!-- 服務資料 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">服務資料</h4>
                </div>
              </div>
              <div class="form_interactive">
                <TextInput
                  class="w50"
                  v-model.trim="user.jobs.department"
                  :item="inputFormat.departmentInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :disabled="isDisabled"
                />
                <TextInput
                  class="w50"
                  v-model.trim="user.jobs.hospital_name"
                  :item="inputFormat.hospitalInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :disabled="isDisabled"
                />
                <Address
                  class="w100"
                  :title="'院所地址'"
                  :city.sync="user.jobs.hospital_city"
                  :area.sync="user.jobs.hospital_area"
                  :zipcode.sync="user.jobs.hospital_zipcode"
                  :addr.sync="user.jobs.hospital_addr"
                  :disabled="isDisabled"
                />
              </div>

              <!-- 必要條件 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">必要條件</h4>
                  <ul class="number_list">
                    <li><p>具本會有效會員資格</p></li>
                    <li><p>具任何科別之專科醫師證照</p></li>
                    <li><p>無專科醫師者，需執業登記滿</p></li>
                  </ul>
                  <p class="paper_clip">
                    需提供 <strong class="txt-dark_green">專科醫師證書影本
                    </strong> 或 <strong class="txt-dark_green">醫師執照正反面影本
                    </strong> (格式 jpg/png/pdf。限制大小：1MB)
                  </p>
                </div>
              </div>
              <div class="form_interactive">
                <Upload
                  class="w100"
                  :class="{ disabled: isDisabled }"
                  v-model="user.files.file_1.file"
                  :item="inputFormat.professionCertifyCopyUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.file_1.title"
                />
                <Upload
                  class="w50"
                  :class="{ disabled: isDisabled }"
                  v-model="user.files.file_2.file"
                  :item="inputFormat.doctorCertifyFrontUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.file_2.title"
                />
                <Upload
                  class="w50"
                  :class="{ disabled: isDisabled }"
                  v-model="user.files.file_3.file"
                  :item="inputFormat.doctorCertifyBackUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.file_3.title"
                />
              </div>

              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">本醫學會同意認可之專業60學分課程</h4>
                  <p class="paper_clip">
                    需提供 <strong class="txt-dark_green">學分上課時數證明
                    </strong> (格式 jpg/png/pdf。限制大小：1MB)
                  </p>
                  <p class="reminder small txt-red">※ 110年12月31日前可免筆試；111年1月1日後申請需達筆試及格</p>
                </div>
              </div>
              <div class="form_interactive">
                <Upload
                  class="w100"
                  :class="{ disabled: isDisabled }"
                  v-model="user.files.file_4.file"
                  :item="inputFormat.creditProofUpload"
                  :fileTitle.sync="user.files.file_4.title"
                />
              </div>

              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn p"
                  :class="{ disabled: isDisabled }"
                  @click="onSubmit"
                >送出</button>
              </div>

            </form>
          </div>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
/** Libraries */
import _ from 'lodash';

/** Components */
import PageSubHeader from '@/components/PageSubHeader.vue';
import TextInput from '@/components/form/TextInput.vue';
import Address from '@/components/form/Address.vue';
import Upload from '@/components/form/Upload.vue';

/** Functions */
import { mapActions } from 'vuex';
import { userGender } from '@/lib/public';
import { AccountApplySubHeader } from '@/lib/const';
import { userInfo, specialistApply } from '@/lib/http';

export default {
  name: 'AccountApplyProfession',
  data() {
    return {
      /** Static Data */
      ...AccountApplySubHeader,
      isDisabled: true,
      inputFormat: {
        phoneInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        telePublicInput: {
          label: '電話(公)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        telInput: {
          label: '電話(家)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        departmentInput: {
          label: '科別',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        professionCertifyCopyUpload: {
          label: '專科醫師證書影本',
          reminder: '',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: true,
        },
        doctorCertifyFrontUpload: {
          label: '醫師證書-正面',
          reminder: '',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: true,
        },
        doctorCertifyBackUpload: {
          label: '醫師證書-反面',
          reminder: '',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: true,
        },
        creditProofUpload: {
          label: '學分上課時數證明',
          reminder: '',
          accept: '.jpg,.png,.pdf',
          size: 1 * 1024 * 1024,
          required: false,
        },
      },

      /** Form Data */
      user: {
        phone: '',
        memo: {
          tel: '',
          office_tel: '',
          // title: '',
        },
        jobs: {
          department: '',
          hospital_name: '',
          hospital_city: '',
          hospital_area: '',
          hospital_zipcode: '',
          hospital_addr: '',
        },
        files: {
          file_1: {
            title: '',
            file: null,
          },
          file_2: {
            title: '',
            file: null,
          },
          file_3: {
            title: '',
            file: null,
          },
          file_4: {
            title: '',
            file: null,
          },
        },
      },

      /** Axios Data */
      fixedInfo: {
        realname: '',
        memberNo: '',
        gender: '',
        birthday: '',
        id_number: '',
      },
      userLevel: undefined,
      userStatus: undefined,
    };
  },
  created() {
    userInfo().then((result) => {
      this.fixedInfo.realname = _.get(result, 'realname', '');
      this.fixedInfo.memberNo = _.get(result, 'no', '');
      this.fixedInfo.gender = _.get(result, 'gender', '');
      this.fixedInfo.birthday = _.get(result, 'birthday', '');
      this.fixedInfo.id_number = _.get(result, 'id_number', '');
      this.user.phone = _.get(result, 'phone', '');
      this.user.memo.tel = _.get(result, 'tel', '');
      this.user.memo.office_tel = _.get(result, 'memo.office_tel', '');
      this.user.jobs.department = _.get(result, 'jobs.department', '');
      this.user.jobs.hospital_name = _.get(result, 'jobs.hospital_name', '');
      this.user.jobs.hospital_city = _.get(result, 'jobs.hospital_city', '');
      this.user.jobs.hospital_area = _.get(result, 'jobs.hospital_area', '');
      this.user.jobs.hospital_zipcode = _.get(result, 'jobs.hospital_zipcode', '');
      this.user.jobs.hospital_addr = _.get(result, 'jobs.hospital_addr', '');
      this.userLevel = _.get(result, 'level', null);
      this.userStatus = _.get(result, 'user_status', null);
    }).then(() => {
      if (parseInt(this.userLevel, 10) !== 1) {
        if (parseInt(this.userStatus, 10) === 1) {
          this.isDisabled = false;
        } else {
          this.openModal({
            message: '您的會員資格已被停權，請洽學會了解更多',
          });
          this.isDisabled = true;
        }
      } else {
        this.openModal({
          message: '您已是專科醫師會員，無須重複申請',
        });
        this.isDisabled = true;
      }
    });
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        const userLevel = this.$cookies.get('level');
        if (parseInt(userLevel, 10) !== 1) {
          specialistApply(this.user).then((result) => {
            const resMsg = _.get(result, 'data.message', '');
            if (result.data.status) {
              this.openModal({
                message: '專科申請已送出',
                url: '/account/search/profession',
              });
            } else {
              this.openModal({
                message: resMsg,
              });
            }
          });
        } else {
          this.openModal({
            message: '您已是專科醫師身份',
          });
        }
      } else {
        this.openModal({
          message: '必填欄位尚未完成！',
        });
      }
    },
    userGender,
  },
  components: {
    PageSubHeader,
    TextInput,
    Address,
    Upload,
  },
};
</script>
